import { graphql } from 'gatsby';
import * as React from 'react';
import * as s from './NewspostTemplate.module.scss';
import Hero from '../components/shared/Hero';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import { Newspost, SEO } from '../types';
import Text from '../components/shared/Text';
import Section from './shared/Section';
import Icon from './swace/icon';
import C from '../colors';
import { Horizontal, Vertical } from './swace/gatsby-background-image';

interface SiteMeta {
  title: string;
  description: string;
  url: string;
}

interface NewspostProps {
  newspost: Newspost;
  siteMeta: SiteMeta;
  seo?: SEO;
}

export class NewspostTemplate extends React.Component<NewspostProps> {
  render() {
    const { newspost, siteMeta, seo } = this.props;
    const {
      title,
      intro,
      body,
      files,
      contact,
      lastChangeDate,
      companyInformation,
    } = newspost.newsPost;

    const date = lastChangeDate.slice(0, 10);
    const time = lastChangeDate.slice(11, 16); 
    const backgroundImage = newspost.newsPost.images && newspost.newsPost.images.length && newspost.newsPost.images[0];
    const imagePositioning = {vertical: Vertical.center, horizontal: Horizontal.center};
    if(backgroundImage)
      seo.opengraphImage = seo.opengraphImage?? {sourceUrl: backgroundImage.url};

    return (
      <Layout siteMeta={siteMeta} seo={seo} disableBackground>
        {newspost.newsPost.images && newspost.newsPost.images.length > 0 && (
          <Hero
            backgroundImage={backgroundImage}
            imagePositioning={imagePositioning}
          />
        )}
        <Section>
          <div className="row">
            <div className="col-12 col-lg-10 offset-lg-1">
              <div className={s.title}>
                <Text h3>{title}</Text>
              </div>
              <p className={s.date}>{`${date} ${time}`}</p>
              {intro && (
                <div className={s.intro}>
                  <Text h5>{intro.replace(/\u00a0/g, ' ')}</Text>
                </div>
              )}
              {body && (
                <div
                  className={s.body}
                  dangerouslySetInnerHTML={{ __html: body }}
                />
              )}
              {files && files.length > 0 && (
                <div className={s.files}>
                  {files.map((file) => (
                    <a
                      key={file.url}
                      href={file.url}
                      target="_blank"
                      className={s.fileLink}
                    >
                      <Icon icon={'file_download'} color={C.GREEN} size={24} />
                      <div className={s.fileTitle}>{file.title}</div>
                    </a>
                  ))}
                </div>
              )}
              {contact && (
                <div
                  className={s.contact}
                  dangerouslySetInnerHTML={{ __html: contact }}
                />
              )}
              {companyInformation && (
                <div
                  className={s.companyInformation}
                  dangerouslySetInnerHTML={{ __html: companyInformation }}
                />
              )}
            </div>
          </div>
        </Section>
        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  fragment NewspostFragment on WpNewspost {
    id
    title
    newsPost {
      body
      companyInformation
      contact
      encryptedId
      files {
        description
        title
        url
      }
      images {
        createDate
        description
        photographer
        title
        url
      }
      intro
      lastChangeDate
      publishDate
      title
    }
    seo {
      title
      metaDesc
      canonical
      opengraphImage {
        sourceUrl
      }
    }
  }
`;

export default NewspostTemplate;
