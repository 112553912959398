import React from 'react';
import { graphql } from 'gatsby';
import { NewspostTemplate } from '../components/NewspostTemplate';

const PressRelease = ({ data }) => {
  const { wpNewspost: newspost } = data;
  const siteMeta = data.wp.generalSettings;
  return (
    <NewspostTemplate
      siteMeta={siteMeta}
      newspost={newspost}
      seo={newspost.seo}
    />
  );
};

export const newspostQuery = graphql`
  query NewspostsById($id: String!) {
    wpNewspost(id: { eq: $id }) {
      ...NewspostFragment
    }
    ...WordpressSiteMetadataFragment
  }
`;

export default PressRelease;
