// extracted by mini-css-extract-plugin
export var body = "NewspostTemplate-module--body--pCcSu";
export var bold = "NewspostTemplate-module--bold--TWFKF";
export var byline = "NewspostTemplate-module--byline--gqsA-";
export var caption = "NewspostTemplate-module--caption--SeTMb";
export var captionSmall = "NewspostTemplate-module--captionSmall--P36nT";
export var center = "NewspostTemplate-module--center--yuuIG";
export var companyInformation = "NewspostTemplate-module--companyInformation--LfmWK";
export var contact = "NewspostTemplate-module--contact--eqDIn";
export var dark = "NewspostTemplate-module--dark--n2mDw";
export var date = "NewspostTemplate-module--date--mslnb";
export var fileLink = "NewspostTemplate-module--fileLink--Adr8n";
export var fileTitle = "NewspostTemplate-module--fileTitle---m0HM";
export var files = "NewspostTemplate-module--files--v7Oiu";
export var h1 = "NewspostTemplate-module--h1--cCYyN";
export var h2 = "NewspostTemplate-module--h2--FD0kc";
export var h3 = "NewspostTemplate-module--h3--sd7zu";
export var h4 = "NewspostTemplate-module--h4--QhGE5";
export var h5 = "NewspostTemplate-module--h5---7bsJ";
export var h6 = "NewspostTemplate-module--h6--0uSi1";
export var ingress = "NewspostTemplate-module--ingress--PtOux";
export var ingressLarge = "NewspostTemplate-module--ingressLarge--KSt7x";
export var ingressMedium = "NewspostTemplate-module--ingressMedium--yaVfs";
export var intro = "NewspostTemplate-module--intro--o6Law";
export var italic = "NewspostTemplate-module--italic--A+fkn";
export var label = "NewspostTemplate-module--label--8fEWp";
export var left = "NewspostTemplate-module--left--AvalT";
export var light = "NewspostTemplate-module--light--VaAG5";
export var pBread = "NewspostTemplate-module--pBread---iBWk";
export var pMedium = "NewspostTemplate-module--pMedium--R9DBZ";
export var pSmall = "NewspostTemplate-module--pSmall--Xh8BH";
export var preLine = "NewspostTemplate-module--preLine--sCxoA";
export var teal = "NewspostTemplate-module--teal--772ea";
export var title = "NewspostTemplate-module--title--3U6Ds";